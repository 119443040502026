<script>
	import Layout from '../../layouts/main'
	import appConfig from '@/app.config'
	import PageHeader from '@/components/page-header'
	import DatePicker from 'vue2-datepicker'

	export default {
		middleware: 'authentication',
		page: {
			title: '',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			DatePicker,
		},
		data: function() {
			return {
				items: [
					{
						text: 'View',
					},
					{
						text: 'Corporate Branch',
						active: true,
					}
				],
				excelDownloading: false,
				moment: this.$moment,
				employeeName: '',
				branch: '',
				shift: '',
				employeeId: '',
				presentData: [],
				absentData: [],
				leaveData: [],
				isReportTableBusy: false,
				totalRows: 1,
				currentPage: 1,
				perPage: 30,
				pageOptions: [10, 25, 30, 50, 100, 500],
				filter: null,
				filterOn: [],
				daterange: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
				sortBy: "updatedAt",
				sortDesc: true,
				fields: [
					{
						key: 'inDateTime',
						label: "In Date Time",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'outDateTime',
						label: "Out Date Time",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'lateArrivalMinutes',
						label: "Late Arrival Minutes",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'earlyDepartureMinutes',
						label: "Early Departure Minutes",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				absentFields: [
					{
						key: 'absentDate',
						label: 'Date',
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'inDateTime',
						label: "In Date Time",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'outDateTime',
						label: "Out Date Time",
						sortable: true,
						tdClass: "align-center align-middle",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
				leaveFields: [
					{
						key: 'leaveDates',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveDuration',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveStartDate',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveEndDate',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveReason',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveType',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: 'leaveStatus',
						sortable: true,
						tdClass: 'align-center align-middle',
						thStyle: { backgroundColor: '#f6f6f6' }
					},
				],
			}
		},
		computed: {
			presentRows: function() {
				return this.presentData.length;
			},
			absentRows: function() {
				return this.absentData.length;
			},
			leaveRows: function() {
				return this.leaveData.length;
			},
		},
		mounted: function() {
			this.employeeId = this.$route.params.employeeId
			this.employeeName = this.$route.params.name
			this.branch = this.$route.params.branch
			this.shift = this.$route.params.shift
			this.getPresentDetails()
			this.getAbsentDetails()
			this.getLeaveDetails()
		},
		methods: {
			getPresentDetails: function() {
				this.axios.post('employee-attendance/list', {
					employeeId: this.employeeId,
					page: 1,
					pageSize: 30,
					startDate: this.moment(this.daterange[0]).format("YYYY-MM-DD 00:00:00"),
					endDate: this.moment(this.daterange[1]).format("YYYY-MM-DD 23:59:59"),
				}).then((result) => {
					this.presentData = result.data.data.listing
				}).catch((error) => {
					console.log(error)
				})
			},
			getAbsentDetails: function() {
				this.axios.post('absent-employee/list', {
					employeeId: this.employeeId,
					page: 1,
					pageSize: 30,
					startDate: this.moment(this.daterange[0]).format("YYYY-MM-DD 00:00:00"),
					endDate: this.moment(this.daterange[1]).format("YYYY-MM-DD 23:59:59"),
				}).then((result) => {
					this.absentData = result.data.data.listing
				}).catch((error) => {
					console.log(error)
				})
			},
			getLeaveDetails: function() {
				this.axios.post('employee-leave/list', {
					employeeId: this.employeeId,
					page: 1,
					pageSize: 30,
					// startDate: this.moment(this.daterange[0]).format("YYYY-MM-DD 00:00:00"),
					// endDate: this.moment(this.daterange[1]).format("YYYY-MM-DD 23:59:59"),
				}).then((result) => {
					this.leaveData = result.data.data.listing
				}).catch((error) => {
					console.log(error)
				})
			},
			onFiltered: function() {},
			applyFilter: function() {
				this.getPresentDetails()
			},
			clearFilter: function() {
				this.daterange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]
				this.getPresentDetails()
			},
			downloadReport: function() {
				this.excelDownloading = true
				let startDate = this.moment(this.daterange[0]).format('YYYY-MM-DD 00:00:00')
				let endDate = this.moment(this.daterange[1]).format('YYYY-MM-DD 23:59:59')
				this.axios.post("admin/download-detailed-attendance-via-date-range",{
					startDate: startDate,
					endDate: endDate,
					employeeIds: [this.employeeId]
				},{
					responseType: "blob",
				}).then((response) => {
					this.excelDownloading = false;
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download", "attendance-report.xlsx")
					document.body.appendChild(link)
					link.click()
				});
			},
		},
	}
</script>
<style>
	.card-body {
		padding: 1.25rem 1.25rem !important;
	}
	.nav-tabs .nav-link.active {
		color: #eb455f!important;
		font-weight: bold;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="card" style="margin-top: -28px">
			<div class="card-body">
				<div class="inner mb-2 row">
					<div class="col-md-4">
						<label>Date Range</label>
						<date-picker v-model="daterange" format="DD MMM Y" append-to-body range lang="en"></date-picker>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<table class="table">
							<tbody>
								<tr>
									<td>Name</td>
									<td>{{ employeeName }}</td>
								</tr>
								<!-- <tr>
									<td>Job Title</td>
									<td>{{  }}</td>
								</tr> -->
								<tr>
									<td>Branch</td>
									<td>{{ branch }}</td>
								</tr>
								<tr>
									<td>Shift</td>
									<td>{{ shift }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<div role="group" class="btn-group">
									<button type="button" class="btn btn-outline-primary" @click="downloadReport()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div>
							</div>
						</div>
						<div>
							<b-tabs content-class="mb-3" fill active-nav-item-class="font-weight-bold">
								<b-tab title="Present">
									<!-- <div class="row mt-4 mb-4">
										<div class="col-sm-12 col-md-4"></div>
										<div class="col-sm-12 col-md-4">
											<div id="tickets-table_length" class="dataTables_length">
												<label class="d-inline-flex align-items-center">
													<span>Show</span>
													<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage"
														size="sm" :options="pageOptions"></b-form-select>
													<span>entries</span>
												</label>
											</div>
										</div>
										<div class="col-sm-12 col-md-4 row">
											<div id="tickets-table_filter" class="dataTables_filter text-md-end">
												<label class="d-inline-flex align-items-center">
													<span>Search:</span>
													<b-form-input v-model="filter" type="search" placeholder="Search..."
														class="form-control form-control-sm ms-2"></b-form-input>
												</label>
											</div>
										</div>
									</div> -->
									<div class="table-responsive mb-0 mt-4">
										<b-table
											striped
											hover
											outlined
											bordered
											:items="presentData"
											:fields="fields"
											thead-class="bg-transparent"
											responsive="sm"
											:per-page="perPage"
											:current-page="currentPage"
											:sort-by.sync="sortBy"
											:sort-desc.sync="sortDesc"
											:filter="filter"
											:filter-included-fields="filterOn"
											@filtered="onFiltered"
											fixed-header
											:busy="isReportTableBusy">
											<template #table-busy>
												<div class="text-center text-danger my-2">
													<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
													<strong class="text-primary">Loading...</strong>
												</div>
											</template>
											<template v-slot:cell(inDateTime)="{ item }">
												<div v-if="item.inDateTime === null">N/A</div>
												<div v-else>{{ moment(item.inDateTime).format('MM-DD-YYYY hh:mm A') }}</div>
											</template>
											<template v-slot:cell(outDateTime)="{ item }">
												<div v-if="item.outDateTime === null">N/A</div>
												<div v-else>{{ moment(item.outDateTime).format('MM-DD-YYYY hh:mm A') }}</div>
											</template>
										</b-table>
									</div>
									<div class="row">
										<div class="col">
											<div class="dataTables_paginate paging_simple_numbers float-end">
												<ul class="pagination pagination-rounded mb-0">
													<b-pagination
														v-model="currentPage"
														:total-rows="presentRows"
														:per-page="perPage" />
												</ul>
											</div>
										</div>
									</div>
								</b-tab>
								<b-tab title="Absent">
									<!-- <div class="row mt-4 mb-4">
										<div class="col-sm-12 col-md-4"></div>
										<div class="col-sm-12 col-md-4">
											<div id="tickets-table_length" class="dataTables_length">
												<label class="d-inline-flex align-items-center">
													<span>Show</span>
													<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage"
														size="sm" :options="pageOptions"></b-form-select>
													<span>entries</span>
												</label>
											</div>
										</div>
										<div class="col-sm-12 col-md-4 row">
											<div id="tickets-table_filter" class="dataTables_filter text-md-end">
												<label class="d-inline-flex align-items-center">
													<span>Search:</span>
													<b-form-input v-model="filter" type="search" placeholder="Search..."
														class="form-control form-control-sm ms-2"></b-form-input>
												</label>
											</div>
										</div>
									</div> -->
									<div class="table-responsive mb-0 mt-4">
										<b-table
											striped
											hover
											outlined
											bordered
											:items="absentData"
											:fields="absentFields"
											thead-class="bg-transparent"
											responsive="sm"
											:per-page="perPage"
											:current-page="currentPage"
											:sort-by.sync="sortBy"
											:sort-desc.sync="sortDesc"
											:filter="filter"
											:filter-included-fields="filterOn"
											@filtered="onFiltered"
											fixed-header
											:busy="isReportTableBusy">
											<template #table-busy>
												<div class="text-center text-danger my-2">
													<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
													<strong class="text-primary">Loading...</strong>
												</div>
											</template>
											<template v-slot:cell(inDateTime)="{ item }">
												<div v-if="item.inDateTime === undefined">N/A</div>
											</template>
											<template v-slot:cell(outDateTime)="{ item }">
												<div v-if="item.outDateTime === undefined">N/A</div>
											</template>
										</b-table>
									</div>
									<div class="row">
										<div class="col">
											<div class="dataTables_paginate paging_simple_numbers float-end">
												<ul class="pagination pagination-rounded mb-0">
													<b-pagination
														v-model="currentPage"
														:total-rows="absentRows"
														:per-page="perPage" />
												</ul>
											</div>
										</div>
									</div>
								</b-tab>
								<b-tab title="Leave">
									<!-- <div class="row mt-4 mb-4">
										<div class="col-sm-12 col-md-4"></div>
										<div class="col-sm-12 col-md-4">
											<div id="tickets-table_length" class="dataTables_length">
												<label class="d-inline-flex align-items-center">
													<span>Show</span>
													<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage"
														size="sm" :options="pageOptions"></b-form-select>
													<span>entries</span>
												</label>
											</div>
										</div>
										<div class="col-sm-12 col-md-4 row">
											<div id="tickets-table_filter" class="dataTables_filter text-md-end">
												<label class="d-inline-flex align-items-center">
													<span>Search:</span>
													<b-form-input v-model="filter" type="search" placeholder="Search..."
														class="form-control form-control-sm ms-2"></b-form-input>
												</label>
											</div>
										</div>
									</div> -->
									<div class="table-responsive mb-0 mt-4">
										<b-table
											striped
											hover
											outlined
											bordered
											:items="leaveData"
											:fields="leaveFields"
											thead-class="bg-transparent"
											responsive="sm"
											:per-page="perPage"
											:current-page="currentPage"
											:sort-by.sync="sortBy"
											:sort-desc.sync="sortDesc"
											:filter="filter"
											:filter-included-fields="filterOn"
											@filtered="onFiltered"
											fixed-header
											:busy="isReportTableBusy">
											<template #table-busy>
												<div class="text-center text-danger my-2">
													<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
													<strong class="text-primary">Loading...</strong>
												</div>
											</template>
											<template v-slot:cell(leaveDates)="{ item }">
												<div v-if="item.leaveDates.length > 1">
													<span>{{ moment(item.leaveDates[0]).utc().format('MMMM DD, YYYY') }}</span>
													<span> - </span>
													<span>{{ moment(item.leaveDates[1]).utc().format('MMMM DD, YYYY') }}</span>
												</div>
												<div v-else>
													<span>{{ moment(item.leaveDates[0]).utc().format('MMMM DD, YYYY') }}</span>
												</div>
											</template>
											<template v-slot:cell(leaveStartDate)="{ item }">
												<div>{{ moment(item.leaveStartDate).utc().format('MMMM DD, YYYY') }}</div>
											</template>
											<template v-slot:cell(leaveEndDate)="{ item }">
												<div>{{ moment(item.leaveEndDate).utc().format('MMMM DD, YYYY') }}</div>
											</template>
										</b-table>
									</div>
									<div class="row">
										<div class="col">
											<div class="dataTables_paginate paging_simple_numbers float-end">
												<ul class="pagination pagination-rounded mb-0">
													<b-pagination
														v-model="currentPage"
														:total-rows="absentRows"
														:per-page="perPage" />
												</ul>
											</div>
										</div>
									</div>
								</b-tab>
							</b-tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>